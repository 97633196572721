html {
  scroll-behavior: smooth;
}
body {
  min-height: 100vh
}
* {
  margin: 0;
  padding: 0;
}
header {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  position: sticky !important;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
}
.header{
  min-height: 100vh;
}
.progress-bar {
  width: 1%;
}
#lets_go {
  z-index: 10;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 50%), 0px 4px 5px 0px rgb(0 0 0 / 54%), 0px 1px 10px 0px rgb(0 0 0 / 52%);
}